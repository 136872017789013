.questionnair-wrapper {
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-top: 20px;
}

.questionnair-wrapper-v2 {
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.head-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.head-wrap > span, .head-wrap > div {
    display: flex;
    align-items: center;
}

.head-wrap > h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin: 0 10px;
}

.head-wrap > div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.head-wrap > span > svg, .head-wrap > div > img {
    width: 20px;
    height: auto;
}

.patient-info {
    display: flex;
    justify-content: space-between;
    top: 77px;
    position: fixed;
    z-index: 1000;
    padding-top: 7px;
    background: #ffffff;
    width: calc(100% - 200px);
    border: "25px";
    box-shadow: 200px 40px 110px rgba(255, 255, 255, 0.05);   
}

@media (max-width: 1100px) {
    .patient-info {
      font-size: "12px";
    }
  }